var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "data-cy": "asset-manage-page"
    }
  }, [_c('div', [_c('AssetForm', {
    attrs: {
      "id": _vm.asset._id,
      "error": _vm.validationErrors
    },
    on: {
      "save": _vm.onUpdate
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }), _c('div', {
    staticClass: "text-right pb-4"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "submit",
      "data-cy": "update-asset-btn",
      "disabled": _vm.loadingAction.update
    },
    on: {
      "click": _vm.onUpdate
    }
  }, [_vm.loadingAction.update ? _c('span', [_c('i', {
    staticClass: "fa fa-spin fa-spinner mr-1"
  }), _vm._v(" Updating...")]) : _c('span', [_c('i', {
    staticClass: "fa fa-check-circle mr-1"
  }), _vm._v(" Update Asset")])])])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }